body {
  z-index: 0;
  background: transparent;
}

a {
  text-decoration: none !important;
}

/* CSS BACKGROUND */
.container-full-bg::before {
  content: "";
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/svg/trongdong.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top;
  opacity: 0.05;
  z-index: -1;
  pointer-events: none;
}

.bg-image {
  background-image: url("../../assets/images/startup-bg.jpg");
}
/* END CSS */

.footer {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

/* CSS MARQUEE-TEXT */
.marquee-container {
  width: 100%; /* Chiều rộng của vùng nhìn thấy */
  overflow: hidden; /* Ẩn phần chữ ra khỏi container */
  white-space: nowrap; /* Chữ nằm trên một dòng */
}

.marquee {
  display: flex;
}

.marquee > h2 {
  padding-right: 70px;
}
/* END CSS */

/* CSS REACT-SELECT */
.custom-select .css-13cymwt-control {
  border: 0;
  background: #f1f2fa;
}

.custom-select .css-13cymwt-control .css-1jqq78o-placeholder,
.custom-select .css-13cymwt-control .css-1dimb5e-singleValue,
.custom-select .css-t3ipsp-control .css-1dimb5e-singleValue {
  color: #787a7c;
}

.custom-select .css-13cymwt-control .css-hlgwow {
  padding: 5px 20px;
}
/* END CSS */

.cursor-pointer {
  cursor: pointer;
}

/* CSS TABLE */
tbody > tr > td,
thead > th {
  padding: 15px;
}

thead > th {
  white-space: nowrap;
}
/* END CSS */

/* CSS STEP */
.invention-progress {
  width: 100%;
}

.invention-progress .steps ul {
  overflow: hidden;
  display: flex;
  width: 60%;
}

.invention-progress .steps ul li {
  width: 25%;
}

.invention-progress .steps ul li div {
  color: #999;
  padding: 10px 0 15px 45px;
  position: relative;
  background: #f5f5f5;
}

.invention-progress .steps ul li div {
  width: 100%;
}

.invention-progress .steps ul li div::before {
  content: " ";
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ddd;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 1;
  margin-left: 1px;
}

.invention-progress .steps ul li div::after {
  content: " ";
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #f5f5f5;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.invention-progress .steps ul li.done div {
  border-color: #20a8d8 !important;
  color: #fff !important;
  background: #20a8d8 !important;
}

.invention-progress .steps ul li.done div::after {
  border-left: 30px solid #20a8d8;
}

.invention-progress .steps ul li.active div {
  border-color: #167495 !important;
  color: #fff !important;
  background: #167495 !important;
}

.invention-progress .steps ul li.active div::after {
  border-left: 30px solid #167495;
}
/* END CSS */

/* CSS PREVIEW PDF */
.preview-pdf {
  height: 1200px;
  width: 100%;
}
/* END CSS */

/* CSS PAGINATION */
.pagination .page-item .page-link.active {
  background-color: #b9e8e0;
  /* border: 1px solid rgba(223, 216, 187, 1); */
}
.page-link {
  width: 36px;
  height: 36px;
}
/* END CSS */

/* CSS MODAL */
.modal-backdrop {
  background-color: #0000004d !important;
}
/* END CSS */

/* CSS AUTOCOMPLETE */
.suggestions {
  background-color: #f1f2fa;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  max-height: 250px;
  overflow: auto;
}

.suggestions > li {
  list-style: none;
  cursor: pointer;
}

.suggestions > li:hover {
  background-color: #c2c7ed;
}
/* END CSS */

/* CSS LOADING */
.loading {
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* END CSS */
